export interface LanguageOption {
    value: string;
    longValue: string;
    label: string;
    countryCode: string;
}

export const LANGUAGES: LanguageOption[] = [
    {
        value: "ar",
        longValue: "ar-SA",
        label: "العربية",
        countryCode: "SA"
    },
    {
        value: "de",
        longValue: "de-DE",
        label: "Deutsch",
        countryCode: "DE"
    },
    {
        value: "fr",
        longValue: "fr-FR",
        label: "Français",
        countryCode: "FR"
    },
    {
        value: "en",
        longValue: "en-GB",
        label: "English",
        countryCode: "GB"
    },
    {
        value: "es",
        longValue: "es-ES",
        label: "Español",
        countryCode: "ES"
    },
    {
        value: "el",
        longValue: "el-GR",
        label: "Ελληνικά",
        countryCode: "GR"
    },
    {
        value: "hi",
        longValue: "hi-IN",
        label: "हिन्दी",
        countryCode: "IN"
    },
    {
        value: "id",
        longValue: "id-ID",
        label: "Bahasa Indonesia",
        countryCode: "ID",
    },
    {
        value: "it",
        longValue: "it-IT",
        label: "Italiano",
        countryCode: "IT"
    },
    {
        value: "ja",
        longValue: "ja-JP",
        label: "日本語",
        countryCode: "JP"
    },
    {
        value: "ko",
        longValue: "ko-KR",
        label: "한국어",
        countryCode: "KR"
    },
    {
        value: "nl",
        longValue: "nl-NL",
        label: "Nederlands",
        countryCode: "NL"
    },
    {
        value: "pt",
        longValue: "pt-PT",
        label: "Português",
        countryCode: "PT"
    },
    {
        value: "ru",
        longValue: "ru-RU",
        label: "Русский",
        countryCode: "RU"
    },
    {
        value: "th",
        longValue: "th-TH",
        label: "ไทย",
        countryCode: "TH"
    },
    {
        value: "tr",
        longValue: "tr-TR",
        label: "Türkçe",
        countryCode: "TR"
    },
    {
        value: "uk",
        longValue: "uk-UA",
        label: "Українська",
        countryCode: "UA"
    },
    {
        value: "vi",
        longValue: "vi-VN",
        label: "Tiếng Việt",
        countryCode: "VN"
    },
    {
        value: "zh",
        longValue: "zh-CN",
        label: "中文",
        countryCode: "CN"
    },
];
